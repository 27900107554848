import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import LineChart from "./scenes/linechart";
import { Routes, Route, Link } from "react-router-dom";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import CurrentTournament from "./scenes/current_tournament";
import Matchups from "./scenes/matchups";
import Captains from "./scenes/captains";
import TopDogsCaptains from "./scenes/top_dogs_captains";
import MostSelected from "./scenes/most_selected";
import Line from "./scenes/linechart";
import BarChart from "./components/BarChart";

function MainNew() {
  // const {state} = useLocation();
  // const overall_standings = state ? state[0] : []
  // const selected_captains = state ? state[1] : []
  // const selected_players = state ? state[2] : []
  // const tournament_name = state ? state[3] : []
  // const curr_tournament_points = state ? state[4] : []

  // const state_local = {
  //     curr_tournament_points2: curr_tournament_points
  // }

  return (
    <div className="app">
      <Sidebar
        defaultCollapsed={true}
        collapsed={true}
        collapsedWidth={11}
        display={{ xs: "none", sm: "" }}
      ></Sidebar>
      <main className="content">
        <Topbar></Topbar>

        <Routes>
          <Route path="/main" element={<Dashboard></Dashboard>}></Route>
          <Route path="/Captains" element={<Captains></Captains>}></Route>
          <Route path="/matchups" element={<Matchups></Matchups>}></Route>

          <Route
            path="/current_tournament"
            element={<CurrentTournament></CurrentTournament>}
          ></Route>
          <Route
            path="/topdogs"
            element={<TopDogsCaptains></TopDogsCaptains>}
          ></Route>
          <Route
            path="/mostselected"
            element={<MostSelected></MostSelected>}
          ></Route>
          <Route path="/line" element={<Line></Line>}></Route>
          <Route path="/bar" element={<BarChart></BarChart>}></Route>
        </Routes>
      </main>
    </div>
  );
}

export default MainNew;
