import * as React from 'react';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material';

/* 
Matchups: (x2 one for captains one for not captains)
- Pos
- Player name
- Total score
- Score today
- On Hole?
- Jop
- Barkie
- Martijn 
- Nicky
- David
*/

const theme = createTheme({
    palette: {
        mode: 'dark'
    },
});

export default function MatchupsTable(props) {
    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ width: "100%", overflowX: "auto" }}>
                        <Typography variant="h6" component="h2">
                            {props.name}
                        </Typography>
                        <Table style={{ tableLayout: 'fixed' }} sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10px" align="left">Pos</TableCell>
                                    <TableCell width="75px" align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Player name</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Total score</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '250px' }}>Score today</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '500px' }}>On Hole</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Jop</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Barkie</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Martijn</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>Nicky</TableCell>
                                    <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>David</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left" scope="row">{row.position}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.name}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.score}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.scoretoday}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.onhole}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.Jop}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.Barkie}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.Martijn}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.Nicky}</TableCell>
                                        <TableCell align="left" style={{ whiteSpace: 'pre-line', minWidth: '100px' }}>{row.David}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
