import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useLocation, useNavigate } from 'react-router-dom';
import { Provider, useSelector } from "react-redux";
import store from "../global/store";

const TopDogsCaptains = (props) => {
    const theme = useTheme();
    const test = props.data
    const colors = tokens(theme.palette.mode);

    const test_data = useSelector((state) => state.data)
    
    const columns = [
        { field: "0", headerName: "Name", width: 150},
        { field: "1", headerName: "Amount chosen", align: 'center'},
    ]

    const rows = test_data ? test_data[5] : []
    const rows2 = test_data ? test_data[6] : []


    return (
        <Provider store={store}>
            <Box m="20px">
                <Header subtitle="Top Dogs Captains"></Header>
                <Box 
                    m="40px 0 0 0" 
                    height="30vh"
                    margin={-1.8}
                    sx={{
                        "& .MuiDataGrid-root": {border: "none"}, 
                        "& .MuiDataGrid-cell": {border: "none"}, 
                        "& .name-column--cell": {color: colors.greenAccent[300]},
                        "& .MuiDataGrid-columnHeaders": {backgroundColor: colors.blueAccent[700], borderBottom: "none"},
                        "& .MuiDataGrid-virtualScroller": {backgroundColor: colors.primary[400]},
                        "& .MuiDataGrid-footerContainer": {backgroundColor: colors.blueAccent[700], borderTop: "none"},
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        
                        
                        }}>
                    <DataGrid density="compact" rows={rows} columns={columns} hideFooter={true}>

                    </DataGrid>

                </Box>
            </Box>
            <Box m="20px">
                <Header subtitle="Top Dogs Players"></Header>
                <Box 
                    m="40px 0 0 0" 
                    height="30vh"
                    margin={-1.8}
                    sx={{
                        "& .MuiDataGrid-root": {border: "none"}, 
                        "& .MuiDataGrid-cell": {border: "none"}, 
                        "& .name-column--cell": {color: colors.greenAccent[300]},
                        "& .MuiDataGrid-columnHeaders": {backgroundColor: colors.blueAccent[700], borderBottom: "none"},
                        "& .MuiDataGrid-virtualScroller": {backgroundColor: colors.primary[400]},
                        "& .MuiDataGrid-footerContainer": {backgroundColor: colors.blueAccent[700], borderTop: "none"},
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        
                        
                        }}>
                    <DataGrid density="compact" rows={rows2} columns={columns} hideFooter={true}>

                    </DataGrid>

                </Box>
            </Box>
        </Provider>
    )
}

export default TopDogsCaptains