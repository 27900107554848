import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "../global/store";

const Matchups = () => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const test_data = useSelector((state) => state.data);

  const columns = [
    { field: "position", headerName: "Pos.", width: 11 },
    {
      field: "name",
      headerName: "Player name",
      disableColumnMenu: true,
      pinned: true,
    },
    {
      field: "score",
      headerName: "Total score",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scoretoday",
      headerName: "Score today",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "onhole",
      headerName: "On Hole",
      headerAlign: "center",
      align: "center",
    },
    { field: "Jop", headerName: "Jop", headerAlign: "center", align: "center" },
    {
      field: "Barkie",
      headerName: "Barkie",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Martijn",
      headerName: "Martijn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Nicky",
      headerName: "Nicky",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "David",
      headerName: "David",
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows = test_data ? test_data[2] : [];
  const tournament_name = `Matchups for: ${test_data[3].name}`;

  return (
    <Provider store={store}>
      <Box m="20px">
        <Header subtitle="Matchups"></Header>
        <Box
          m="40px 0 0 0"
          height="53vh"
          margin={-1.8}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { border: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            hideFooter={true}
          ></DataGrid>
        </Box>
      </Box>
    </Provider>
  );
};

export default Matchups;
