import './App.css';
import LoadingScreen from "./LoadingScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./MainPage";
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material'
import MainNew from './MainNew';
import store from './scenes/global/store'
import { Provider } from "react-redux";


function App() {
  const [theme, colorMode] = useMode();

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoadingScreen />} />
          </Routes>
          <MainNew></MainNew>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;