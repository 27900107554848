import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './App.css';
import TableExample from "./StandingsTable";
import BasicTableCurrentTournament from "./CurrentTournementTable"
import MatchupsTable from "./MatchupsTable";
import axios from "axios";

function MainPage() {
    const matchups = 'Matchups'
    const captains = "Captains"
    let {state} = useLocation();
    const overall_standings = state ? state[0] : []
    const selected_captains = state ? state[1] : []
    const selected_players = state ? state[2] : []
    const tournament_name = state ? state[3] : []
    const curr_tournament_points = state ? state[4] : []
    const navigate = useNavigate();
    const internal = 'http://127.0.0.1:8080/api/matchups'
    const prod = process.env.REACT_APP_API_URL + '/matchups'

    useEffect(() => {
      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        axios
        .get(internal)
        .then((res) => {
          state = res.data
        })
      });
    }, []);

    return (
        <div className="main-screen">
            <div className="nav-bar">
                <div className="logo-area">
                    <div className="ring"></div>
                    <div className="ring1"></div>
                    <div className="ring2"></div>
                </div>
                <div className="nav-buttons">
                    <ul>
                        <li><a href="">Home</a></li>
                        <li><a href="">Top 100</a></li>
                        <li><a href="">Charts</a></li>  
                    </ul>
                </div>
            </div>
            <div className="content">
                <table className="table-overall"><TableExample data={overall_standings}></TableExample></table>
                <table className="table-tournament"><BasicTableCurrentTournament tourn_name={tournament_name} data={curr_tournament_points}></BasicTableCurrentTournament></table>
                <table className="table-matchups-captains"><MatchupsTable name={captains} data={selected_captains}></MatchupsTable></table>
                <table className="table-matchups"><MatchupsTable name={matchups} data={selected_players}></MatchupsTable></table>
            </div>
        </div>

    );
}

export default MainPage;