import * as React from 'react';
import {Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from '@mui/material';

/*
Columns Overall:
- Position
- Name
- Total Points
- Difference 
*/

/* 
Columns Current tournament
- Position
- Name
- Points
*/


/* 
Matchups: (x2 one for captains one for not captains)
- Pos
- Player name
- Total score
- Score today
- On Hole?
- Jop
- Barkie
- Martijn 
- Nicky
- David
*/

const theme = createTheme({
    palette: {
        mode: 'dark'
    },
});

export default function BasicTable(props) {
    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ width: '99%' }}>
                        <Typography variant="h6" component="h2">
                           Current Tournament
                        </Typography>
                        <Table style={{}} sx={{  }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Difference </TableCell>
                                    <TableCell align="left">Points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.map((row, index) => (
                                    <TableRow
                                        key={row.Name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left" scope="row">{index + 1}</TableCell>
                                        <TableCell align="left">{row.Name}</TableCell>
                                        <TableCell align="left">{row.Difference}</TableCell>
                                        <TableCell align="left">{row.TotalPoints}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
