import * as React from 'react';
import {Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material';

/*
Columns Overall:
- Position
- Name
- Total Points
- Difference 
*/

/* 
Columns Current tournament
- Position
- Name
- Points
*/


/* 
Matchups: (x2 one for captains one for not captains)
- Pos
- Player name
- Total score
- Score today
- On Hole?
- Jop
- Barkie
- Martijn 
- Nicky
- David
*/

const theme = createTheme({
    palette: {
        mode: 'dark'
    },
});


const rows = [
    { position: 1, name: 'Nickydb', points: 848 },
    { position: 2, name: 'martijnvandooren', points: 828 },
    { position: 5, name: 'Jopwiel', points: 815 },
    { position: 4, name: 'Barkie', points: 708 },
    { position: 5, name: 'Birdy-D', points: 564 },
];

export default function BasicTableCurrentTournament(props) {
    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={12}>
                    {/* <p className='table-title'>Tournament: {props.tourn_name.name}</p> */}
                    <TableContainer component={Paper}>
                        <Typography variant="h6" component="h2">
                            Tournament: {props.tourn_name.name}
                        </Typography>
                        <Table sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="5%">Position</TableCell>
                                    <TableCell width="10%" align="left">Name</TableCell>
                                    <TableCell align="left">Points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.map((row, index) => (
                                    <TableRow
                                        key={row.Name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left" scope="row">{index + 1}</TableCell>
                                        <TableCell align="left">{row.Name}</TableCell>
                                        <TableCell align="left">{row.Points}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
